@import "../styles/variables.scss";

.userData {
  .firstContainer {
    bottom: 0px;
    content: "";
    height: 400px;
    pointer-events: none;
    transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    width: 400px;
    will-change: left, right, background, opacity;
    background: radial-gradient(
      at right bottom,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0) 75%
    );
    position: fixed;
    z-index: 2000;
    right: 0px;
  }

  .showFC {
    opacity: 1;
  }
  .hideFC {
    opacity: 0;
  }

  .dataWrapper {
    bottom: 8%;
    right: 2%;
    position: fixed;
    display: none;
    width: 350px;
    min-height: 250px;
    max-height: 650px;
    border-radius: 12px;
    // opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.15) 0px 5px 30px 0px, rgba(0, 0, 0, 0.05) 0px 3px 3px 0px;
    // display: none;
    z-index: 2000;
    height: fit-content;
    overflow: hidden;
    transform: translate(0%, 0%);
    background: $white;
    animation: fadeIn 0.3s ease-in-out forwards;
  }

  .showDW {
    display: block;
    animation: fadeIn 0.3s ease-in-out forwards;
    // animation: fadeOut 0.3s ease-in-out forwards;
    // opacity: 1;
    // transform: translateY(0px);
  }

  .hideDW {
    display: none;
    animation: fadeOut 0.3s ease-in-out forwards; /* Animation for hiding dialog */
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translate(0%, 0%) scale(1);
    }
    to {
      opacity: 0;
      transform: translate(0%, 0%) scale(0.8);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(0%, 0%) scale(0.8);
    }
    to {
      opacity: 1;
      transform: translate(0%, 0%) scale(1);
    }
  }

  .head {
    padding: 12px 24px;
    background-color: $blue1;
    color: $white;
  }

  .modalData {
    padding: 24px;
  }

  .inputContent {
    font-size: 14px;
  }

  .submitBtn {
    width: 100%;
  }
}

.userDataWrapper {
  padding: 64px 96px;
  height: 100%;
  .logoImage {
    height: 44px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .formWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
  }
  .w48 {
    width: 48%;
  }
  .inputContent {
    font-size: 14px;
  }
  .checkBox{
    padding: 4px;
    color: $blue1 !important;
  }
  .btnWrapper {
    // padding-top: 64px;
    width: 100%;
  }
  .submitBtn {
    width: 100%;
    background: #2d2d2d;
    &:hover {
      background: var(
        --Btn-grad,
        linear-gradient(
          89deg,
          #2d2d2d -15.5%,
          #504e9c 57.81%,
          #f1d5ac 102.91%
        )
      );
    }
  }
  .columnSpaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
}
