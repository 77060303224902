@import "../../styles/variables.scss";

.homePageWrapper {
  overflow: hidden auto;
  height: 100vh;
}

.headerWrapper {
  background: linear-gradient(180deg, #cfe5ff 0%, #fff 21.25%, #fff0d1 100%);
}

.backgroundWrapper {
  background-color: #fafaff;
}

.sectionHomeOneWrapper {
  padding: 121px 182px 0 121px;
  .gitBtn {
    background: #16161a;
    color: #fff;
    text-transform: capitalize;
  }
  .logoImageWrapper {
    height: 66px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .imageWrapper {
    margin-top: 112px;
    position: relative;

    video {
      border-radius: 21.959px;
      border: 5.912px solid #fff3db;
      background: #fff;
      box-shadow: 0px 0px 27.027px 0px rgba(131, 131, 144, 0.38);
      width: 1066px;
      height: 635px;
      object-fit: fill;
    }
    &::after {
      content: "";
      background-image: url("./../../assets/images/Star2.png");
      position: absolute;
      top: -48px;
      width: 152px;
      height: 152px;
      right: -103px;
      background-size: cover;
      z-index: 1000;
    }
    &::before {
      content: "";
      background-image: url("./../../assets/images/Star2.png");
      position: absolute;
      top: 500px;
      width: 113px;
      height: 113px;
      left: -85px;
      background-size: cover;
      z-index: 1000;
    }
  }
  .partnerWrapper {
    padding-block: 164px 128px;
    max-width: 1362px;
  }
  .partnerImages {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 80px;
    row-gap: 16px;
    max-width: 1137px;
    .cImage {
      height: 63px;
      width: 184px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.sectionHomeTwoWrapperOne {
  padding: 96px 112px;

  .image {
    padding-top: 40px;
  }
  .poBtn {
    font-size: 21px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .boxContent {
    border-right: 1px solid var(--Neutral-200, #eaedf2);
  }

  .checkListHead {
    padding: 32px 16px 32px 24px;
    border-bottom: 1px solid #eaedf2;
  }
  .checkListHead2 {
    padding: 10.5px 16px 14px 24px;
    border-bottom: 1px solid #eaedf2;
  }

  .checkListContent {
    padding: 32px 24px 48px 24px;
  }

  .colHead1 {
    color: #4e4aff;
    letter-spacing: 1.08px;
    text-transform: uppercase;
  }

  .colHead2 {
    letter-spacing: 1.08px;
    text-transform: uppercase;
  }

  .logoImageWrapper {
    height: 35.4px;
    width: 96px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.sectionHomeTwoWrapperTwo {
  padding: 96px 112px;
  background: #fafaff;

  .feedbackContent {
    max-width: 464px;
  }

  .cImage {
    height: 48px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .authorImage {
    height: 66px;
    width: 66px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 20px;
    }
  }
}

.sectionHomeThreeWrapper {
  padding: 96px 112px;

  .tabLabel {
    padding: 32px 48px;
    border-radius: 24px;
    border: 1px solid #dedbec;
    margin-inline: 6px;
    background-color: $white;
    &:hover {
      border: 3px solid #4e4aff;
      .image {
        mix-blend-mode: normal;
      }
    }
    &.activeTab {
      border: 3px solid #4e4aff;
      .image {
        mix-blend-mode: normal;
      }
    }
    .image {
      mix-blend-mode: luminosity;
    }
  }

  .tabHead {
    max-width: 524px;
  }

  .tabContent {
    color: var(--Grey-400, #757185);
    max-width: 618px;
  }

  .tabContentWrapper {
    padding-top: 42px;
    width: 100%;
  }

  .imageContainer {
    height: 48px;
    width: 196px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .contentImg {
    border-radius: 20px;
    height: 312px;
    background: var(--Neutral-200, #eaebf2);
    img {
      height: 100%;
      width: 100%;
      border-radius: 20px;
    }
  }
}

.sectionHomeFourWrapper {
  padding: 96px 112px;
  .contentWrapper {
    gap: 147px;
    padding-top: 100px;
    padding-left: 80px;
    width: 1273px;
    overflow: hidden;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      #d0e6ff -12.7%,
      #fff 25.33%,
      #fff1d3 128.16%
    );

    .learnMoreBtn {
      background: #16161a;
      color: #fff;
      text-transform: capitalize;
      &:hover {
        background: var(
          --Btn-grad,
          linear-gradient(
            89deg,
            #2d2d2d -15.5%,
            #504e9c 57.81%,
            #f1d5ac 102.91%
          )
        );
      }
    }

    .contentImage {
      width: 457px;
      height: 493px;
      border-radius: 17.034px 0px 0px 0px;
      border-top: 4.586px solid #fff3db;
      border-left: 4.586px solid #fff3db;
      background: #fff;
      overflow: hidden;
      box-shadow: 0px 0px 20.965px 0px rgba(131, 131, 144, 0.38);
      // mask-image: url("./../../assets/images/InsureToday.png");
      img {
        height: 100%;
        width: 100%;
        // border-radius: 17.034px 0px 0px 0px;
        // border-top: 4.586px solid #fff3db;
        // border-left: 4.586px solid #fff3db;
        // background: #fff;
        // box-shadow: 0px 0px 20.965px 0px rgba(131, 131, 144, 0.38);
        object-fit: cover;
      }
    }
  }
}

.sectionHomeFiveWrapper {
  padding: 96px 112px;
  .infoWrapper {
    flex-wrap: wrap;
  }

  .infoBox {
    padding: 32px;
    width: calc(33.33% - 80px);
    border-radius: 28px;
    border: 1px solid #e6ecf6;
  }

  .cImage {
    height: 28px;
    width: 28px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .authorImage {
    height: 52px;
    width: 52px;
    img {
      height: 100%;
      object-fit: cover;
      border-radius: 14px;
      width: 100%;
      object-position: top;
    }
  }
}

.sectionHomeSixWrapper {
  padding: 96px 112px;
  background: #fafaff;

  .infoWrapper {
    flex-wrap: wrap;
    row-gap: 56px;
    column-gap: 72px;
  }

  .infoBox {
    padding: 16px;
    width: calc(33.33% - 80px);
  }
  .iconImage {
    height: 28px;
    width: 28px;
    display: flex;
    img {
      height: 100%;
      width: 135%;
      object-fit: contain;
    }
  }
}

.subFooterWrapper {
  padding: 96px 112px;
  color: $white;

  .contentWrapper {
    padding: 72px 88px;
    border-radius: 43px;
    background: #16161a;
    max-width: 1040px;
  }

  .learnMoreBtn {
    background-color: $white;
    color: #16161a;
    text-transform: capitalize;
  }
}


.userData {
  .firstContainer {
    bottom: 0px;
    content: "";
    height: 400px;
    pointer-events: none;
    transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    width: 400px;
    will-change: left, right, background, opacity;
    background: radial-gradient(
      at right bottom,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0) 75%
    );
    position: fixed;
    z-index: 2000;
    right: 0px;
  }

  .showFC {
    opacity: 1;
  }
  .hideFC {
    opacity: 0;
  }

  .dataWrapper {
    bottom: 0;
    right: 0;
    left: 90%;
    position: fixed;
    width: 350px;
    min-height: 250px;
    max-height: 650px;
    border-radius: 4px;
    overflow: visible;
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.15) 0px 5px 30px 0px, rgba(0, 0, 0, 0.05) 0px 3px 3px 0px;
    display: none;
    z-index: 2000;
    height: calc(100vh - 20px - 0px);
  }

  .showDW {
    display: none;
  }

  .hideDW {
    display: block;
    opacity: 1;
    transform: translateY(0px);
  }
}
