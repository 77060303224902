.alignJustifyCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignJustifySpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textAlignCenter {
  text-align: center;
}

.alignJustifyStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.alignJustifySpaceEvenly {
  display: flex;
  justify-content: space-evenly;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.alignStart {
  display: flex;
  align-items: start;
}

.justifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

.justifyCenter {
  display: flex;
  justify-content: center;
}

.flexDirectionColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexDirectionColumnStart {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.contentWidth {
  max-width: 90rem;
  margin: 0 auto;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}
.h100{
  height: 100%;
}
.gap22 {
  gap: 22px;
}

.gap24 {
  gap: 24px;
}

.gap54 {
  gap: 54px;
}

.gap208 {
  gap: 208px;
}
.gap72 {
  gap: 72px;
}
.gap21 {
  gap: 21px;
}
.gap145 {
  gap: 145px;
}

.gap36 {
  gap: 36px;
}

.gap104 {
  gap: 104px;
}
.gap14 {
  gap: 14px;
}
.gap8 {
  gap: 8px;
}
.gap16 {
  gap: 16px;
}
.gap41 {
  gap: 41px;
}
.gap48 {
  gap: 48px;
}
.gap101 {
  gap: 101px;
}
.gap39 {
  gap: 39px;
}
.gap123 {
  gap: 123px;
}
.gap60 {
  gap: 60px;
}
.gap62 {
  gap: 62px;
}
.gap67 {
  gap: 67px;
}
.gap40 {
  gap: 40px;
}
.gap20 {
  gap: 20px;
}
.gap44 {
  gap: 44px;
}
.gap41 {
  gap: 41px;
}
.gap65 {
  gap: 65px;
}

.gap32 {
  gap: 32px;
}
.gap64 {
  gap: 64px;
}
.gap12 {
  gap: 12px;
}
.gap40 {
  gap: 40px;
}
.gap20 {
  gap: 20px;
}
.gap35 {
  gap: 35px;
}
.gap33 {
  gap: 33px;
}
.gap72 {
  gap: 72px;
}
.gap80 {
  gap: 80px;
}
.gap290 {
  gap: 290px;
}
.gap200 {
  gap: 200px;
}
.gap27 {
  gap: 27px;
}
.gap96{
  gap: 96px
}