@import "../../styles/variables.scss";

.aboutUsPageWrapper {
  overflow: hidden auto;
  height: 100vh;
}

.headerWrapper {
  background: linear-gradient(180deg, #cfe5ff 0%, #fff 21.25%, #fff0d1 100%);
}

.sectionAboutUsOneWrapper {
  padding: 80px 112px 0 112px;

  .headWrapper {
    max-width: 796px;
  }
  .image {
    width: 796px;
    height: 370px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.sectionAboutUsTwoWrapper {
  padding: 96px 112px 148px 112px;

  .textWrapper {
    max-width: 682px;
  }

  .image {
    width: 460px;
    height: 422px;
    overflow: hidden;
    border-radius: 33.786px;
    background: #e1dbfb;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.sectionAboutUsThreeWrapper {
  padding: 44px 112px 96px 112px;

  .headText {
    color: #7b61ff;
    letter-spacing: -1.56px;
  }

  .subHeadText {
    font-size: 34px;
    font-weight: 400;
    letter-spacing: -1.02px;
  }

  .teamContainer {
    display: flex;
    gap: 36px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .teamInfoWrapper {
    border-radius: 43px;
    background: linear-gradient(
      180deg,
      #fff1d3 -7.61%,
      #fff 64.22%,
      #adbdf3 90.79%
    );
    width: 382px;
    height: 560px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .content,
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }

  .overlay {
    transform: translateY(100%);
  }

  .teamInfoWrapper:hover .overlay {
    transform: translateY(0);
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .contentText {
      padding-left: 42px;
      padding-top: 66px;
    }
    &::after {
      content: "";
      background-image: url("./../../assets/images/Star3.svg");
      position: absolute;
      top: 65px;
      width: 182px;
      height: 260px;
      right: 0px;
      background-size: cover;
    }
    .image {
      height: 353px;
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img {
        height: 100%;
        width: 353px;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .overlayContent {
    background: linear-gradient(
      191deg,
      #fff1d3 -1.47%,
      #4e4c93 31.78%,
      #1a1a2e 78.62%
    );
    padding: 64px 35px 64.615px 36px;
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 18px;

    span {
      color: $white;
      line-height: 30px;
    }
    img {
      height: 25px;
      width: 25px;
    }
  }
}
