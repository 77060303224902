.backgroundWrapper {
  background-color: #fafaff;
}

.footerWrapper {
  padding: 64px 112px;
  background: #fafaff;

  .logoImageWrapper {
    width: 108px;
    height: 52px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .textImageWrapper {
    width: 73.044px;
    height: 21.691px;
  }

  .footerLink {
    color: #9292b8;
    cursor: pointer;
    &:hover {
      color: #4e4aff;
    }
  }
}
