@import "../../styles/variables.scss";

.headerContentWrapper {
  position: relative;
  padding: 48px 112px 0px 112px;
  height: 100%;
  .logoImageWrapper {
    width: 108px;
    height: 52px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .textImageWrapper {
    width: 73.044px;
    height: 21.691px;
  }
  .gsBtn {
    background: #16161a;
    color: #fff;
    text-transform: capitalize;
  }
}

.menuHeadWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    .dropdownContainer {
      margin-left: 10px;

      .subMenuBox {
        min-width: 524px;
      }
    }
  }
}

.menuWrapper {
  position: relative;
}

.dropdown {
  height: 100%;
  padding-inline: 16px;

  .menuHeadLabel {
    cursor: pointer;
  }

  .active {
    color: #4e4aff;
    font-weight: 600;
  }

  .subMenuHeadLabel {
    color: #9aa1b0;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    padding-inline: 18px;
  }

  &:hover {
    .menuHeadLabel {
      color: #4e4aff;
      font-weight: 600;
    }
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  top: calc(100%);
  z-index: 1000;
  flex-direction: column;
  left: 0px;
  right: 0px;
  width: 100%;
  // padding-top: 8px;
}

.showContainer {
  display: block;
}

.dropdownContainer {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.subMenuWrapper {
  padding: 24px 12px 16px 12px;
  border-radius: 20px;
  border: 1px solid var(--Neutral-200, #eaedf2);
  background: var(--Generic-White, #fff);
  box-shadow: 2px 10px 22px 0px #ececf1;
  width: fit-content;
}

.subMenuBox {
  padding: 12px;
  cursor: pointer;
  min-width: 406px;

  &:hover,
  &.active {
    background: #f7f7ff;
    border-radius: 12px;
    .starIcon {
      path {
        fill: #4e4aff;
      }
    }
    .linkIcon {
      path {
        stroke: #4e4aff;
      }
    }
    p {
      color: #4e4aff;
    }
  }
}
